<template>
  <!-- 右侧内容 -->
  <div class="right">
    <div class="right_top">
      <h3>手势识别</h3>
      <p>
        【TiFancy美颜SDK】手势识别技术即可以提供独立的SDK版本，也可以集成在我们的人脸特效SDK中。我们的手势识别技术目前包括多种手势比心、正反竖大拇指、摇滚吧等。我们的技术研发团队正致力于更多更复杂的手势识别技术中，以满足更多的场景需求。
      </p>
      <div class="top_img">
        <img :src="topIndex == 0 ? topDef : topSel" />
        <span
          class="top_span"
          @mouseover="topIndex = 1"
          @mouseleave="topIndex = 0"
          >比ok</span
        >
      </div>
    </div>
    <div>
      <div class="right_item">
        <div class="item_img">
          <img :src="gun == 0 ? gunDef : gunSel" />
          <span class="item_span" @mouseover="gun = 1" @mouseleave="gun = 0"
            >比枪</span
          >
        </div>
        <div class="item_text">
          <h3>互动玩法</h3>
          <p>
            基于手势识别技术，实现用特定手势或动作来触发有趣好玩的AR贴纸特效，大大增强了人机互动可玩性，并且支持根据特定场景定制各种应景的贴纸特效。
          </p>
        </div>
      </div>
      <div class="right_item">
        <div class="item_text">
          <h3>多种手势</h3>
          <p>
            拓幻美颜SDK技术研发团队正致力于更多更复杂的手势识别技术，目前已可以精确追踪比心、正反竖大拇指、抱拳等手势。
          </p>
        </div>
        <div class="item_img">
          <img :src="heart == 0 ? heartDef : heartSel" />
          <span class="item_span" @mouseover="heart = 1" @mouseleave="heart = 0"
            >比心</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      topDef: require("@/assets/product/beautySDK/手势识别/手势识别--大图--比ok前gif.gif"),
      topSel: require("@/assets/product/beautySDK/手势识别/手势识别--大图--比ok后gif.gif"),
      gunDef: require("@/assets/product/beautySDK/手势识别/手势识别--互动玩法--比枪gif.gif"),
      gunSel: require("@/assets/product/beautySDK/手势识别/手势识别--互动玩法--比枪后gif.gif"),
      heartDef: require("@/assets/product/beautySDK/手势识别/手势识别--多种手势--比心gif.gif"),
      heartSel: require("@/assets/product/beautySDK/手势识别/手势识别--多种手势--比心后gif.gif"),
      //悬停数据
      topIndex: 0,
      gun: 0,
      heart: 0,
    };
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 69.2%;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  // 相同样式
  span {
    display: block;
    width: 100px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
  }
  span:hover {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #ffffff;
  }
  .right_top {
    h3 {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
    p {
      line-height: 24px;
      margin: 20px 0;
    }
    .top_img {
      width: 100%;
      position: relative;
      img {
        width: 100%;
      }
      .top_span {
        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 10;
      }
    }
  }
  .right_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    .item_img:nth-child(1),
    .item_img:nth-child(2) {
      width: 50.6%;
      position: relative;
      img {
        width: 100%;
      }
      .item_span {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 10;
      }
    }
    .last_img {
      width: 50.6%;
      overflow: hidden;
      img {
        width: 100%;
        transition: all 1s;
      }
      img:last-child:hover {
        transform: scale(1.2);
      }
    }

    .item_text {
      width: 43.1%;
      h3 {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
      p {
        line-height: 24px;
        margin: 20px 0;
      }
    }
  }
}
</style>