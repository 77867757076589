<template>
  <div>
    <!-- 大图部分 -->
    <BigBgimg :list="bigData"></BigBgimg>
    <!-- 中间内容部分 -->
    <div class="conter">
      <!-- 面包屑导航部分 -->
      <BreadNav></BreadNav>
      <!-- 列表部分 -->
      <div class="nav_content">
        <!-- 左侧标题 -->
        <ul class="left">
          <li
            :class="index == selectId ? 'select_li' : ''"
            v-for="(item, index) in leftList"
            :key="index"
            @click="show(item, index)"
          >
            {{ item.title }}
          </li>
        </ul>
        <!-- 右侧内容 -->
        <global v-if="selectId == 0"></global>
        <type v-else-if="selectId == 2"></type>
        <filterPage v-else-if="selectId == 4"></filterPage>
        <interact v-else-if="selectId == 7"></interact>
        <keyBeauty v-else-if="selectId == 11"></keyBeauty>
        <gesture v-else-if="selectId == 15"></gesture>
        <div class="right" v-else>
          <div class="right_top">
            <h3>{{ showItem.title }}</h3>
            <p>
              {{ showItem.topText }}
            </p>
            <div class="item_img">
              <img :src="showItem.topImg" />
            </div>
          </div>
          <div v-for="(item, index) in showItem.item" :key="index">
            <div class="right_item" v-if="index % 2 == 0">
              <div class="item_img">
                <img :src="item.itemImg" alt="" />
              </div>
              <div class="item_text">
                <h3>{{ item.itemTitle }}</h3>
                <p>
                  {{ item.itemText }}
                </p>
              </div>
            </div>
            <div class="right_item" v-else>
              <div class="item_text">
                <h3>{{ item.itemTitle }}</h3>
                <p>
                  {{ item.itemText }}
                </p>
              </div>
              <div class="item_img">
                <img :src="item.itemImg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BigBgimg from "@/components/BigBgimg/index"; //大图
import global from "./child/global"; //全局美颜页面
import type from "./child/type"; //精致美型页面
import filterPage from "./child/filterPage"; //滤镜特效
import interact from "./child/interact"; //互动贴纸
import keyBeauty from "./child/keyBeauty"; //一键美颜
import gesture from "./child/gesture"; //手势识别
import BreadNav from "@/components/BreadNav/index"; //面包屑导航
/* import bus from '@/util/bus' //传参 */

export default {
  components: {
    BigBgimg,
    global,
    type,
    filterPage,
    interact,
    keyBeauty,
    gesture,
    BreadNav,
  },
  data() {
    return {
      // 大图数据
      bigData: {
        topTitle: "拓幻美颜SDK",
        topText:
          " 精准控制的美颜效果和全局影像美化的美颜/萌颜SDK",
          topText2:
          "给每一位用户带来极致的使用体验", 
        topBtn: "联系我们",
        topImg: require("@/assets/product/美颜首图.png"),
        topBg:require("@/assets/product/美颜首图bg.png"),
      },
      //左侧导航数据
      leftList: [
        // 单独页面
        {
          title: "全局美颜",
        },
        {
          title: "精准美肤",
          topText:
            "【TiFancy美颜SDK】可精准识别人体肤色，捕捉面部五官，针对人脸及其他部位的皮肤实现可供自由调节的精致美肤功能，精准调节，满足全场景使用。",
          topImg: require("@/assets/product/beautySDK/精准美肤/精准美肤--大图.png"),
          item: [
            {
              itemTitle: "精准识别",
              itemText:
                "基于拓幻科技自研的智能识别技术，能有效实现人景分割，精准识别人体轮廓和肤色，做到精准美肤。",
              itemImg: require("@/assets/product/beautySDK/精准美肤/精准美肤--精准识别.png"),
            },
            {
              itemTitle: "可靠性",
              itemText:
                "美颜效果不受环境影响，完美适应逆光、暗角等环境，支持单人及多人模式下自拍、直播和视频。",
              itemImg: require("@/assets/product/beautySDK/精准美肤/精准美肤--可靠性.png"),
            },
          ],
        },
        // 单独页面
        {
          title: "精致美型",
        },
        {
          title: "动态贴纸",
          topText:
            "【TiFancy美颜SDK】动态贴纸依附于先进的人脸追踪技术，可进行106关键点实时追踪，并结合了贴纸和人脸的贴合算法，实现时尚，酷炫，可爱，搞怪的动态贴纸。贴纸可根据用户需求，进行自定义设计。",
          topImg: require("@/assets/product/beautySDK/动态贴纸/动态贴纸--大图gif.gif"),
          item: [
            {
              itemTitle: "多种款式",
              itemText:
                "拓幻贴纸库拥有多套动态贴纸可选择使用，可存放云端也可存放本地。针对不同的人群也会有不同的识别技术，比如针对婴幼儿识别率更是达到99.7%。总有一款适合你，可以清新可爱，可以精灵古怪，更可以酷炫高端。",
              itemImg: require("@/assets/product/beautySDK/动态贴纸/动态贴纸--多种款式gif.gif"),
            },
            {
              itemTitle: "个性定制",
              itemText:
                "个性定制贴纸，按照自己的审美或需求，定制适合自己用户的产品。我们为您提供生成贴纸配置文件工具以及贴纸制作样例，可自行个性化配置设计，也可将设计要求提给我们，我们热情，专业的设计师会和您沟通并为您提供一站式定制设计服务。",
              itemImg: require("@/assets/product/beautySDK/动态贴纸/动态贴纸--个性定制gif.gif"),
            },
          ],
        },
        // 单独页面
        {
          title: "滤镜特效",
        },
        {
          title: "美妆特效",
          topText:
            "【TiFancy美颜SDK】依托于人脸跟踪技术，实现实时美妆，瞬间拥有靓丽妆容。支持口红、腮红、眉毛、眼影、眼线、睫毛、美瞳自由组合，自定义色值，调整妆容，风格多样 。",
          topImg: require("@/assets/product/beautySDK/美妆特效/美妆特效--大图.png"),
          item: [
            {
              itemTitle: "实时美妆",
              itemText:
                "基于106个人脸关键点，为客户提供多款包括腮红、睫毛、眉毛、眼影、眼线在内的美妆特效，实时渲染。",
              itemImg: require("@/assets/product/beautySDK/美妆特效/美妆特效--实时美妆.png"),
            },
            {
              itemTitle: "自定义妆容",
              itemText:
                "支持多款美妆特效自由组合，支持客户自定义色值，真实还原质地，轻松变妆。",
              itemImg: require("@/assets/product/beautySDK/美妆特效/美妆特效--自定义妆容.png"),
            },
          ],
        },
        {
          title: "抖动特效",
          topText:
            "【TiFancy美颜SDK】抖动特效根据先进的图像渲染算法，实现类似抖音特效的魔法黑森林，灵魂出窍，炫彩抖动，以及分屏镜像效果。抖动特效可进行实时效果预览，也可进行后期处理，不仅可以对视频帧渲染，也可以对静态图片进行渲染。",
          topImg: require("@/assets/product/beautySDK/抖动特效/抖动特效--大图.png"),
          item: [
            {
              itemTitle: "动态抖屏",
              itemText:
                "稳定并且高效的动态抖屏效果，将轮廓进行算法层面动态抖动，实现各种鬼畜效果，并且可以根据用户需求实现不同动态效果。总有一款适合你，给你带来不一样的短视频体验。",
              itemImg: require("@/assets/product/beautySDK/抖动特效/抖动特效--动态抖屏gif.gif"),
            },
            {
              itemTitle: "分屏抖动",
              itemText:
                "分屏抖动效果，可将画面分为左右镜像，或四分屏，十六分屏等动态效果，让用户自由发挥想象力，拍摄出最天马行空效果的短视频。支持个性化定制，可根据用户使用情况，提出定制化需求，我们专业的视频处理团队将为您提供一站式定制设计服务。",
              itemImg: require("@/assets/product/beautySDK/抖动特效/抖动特效--分屏抖动gif.gif"),
            },
          ],
        },
        // 单独页面
        {
          title: "互动贴纸",
        },
        {
          title: "水印LOGO",
          topText:
            "【TiFancy美颜SDK】特效水印添加为特效相机、直播、短视频平台提供针对实时视频、图片添加自定义水印功能，位置、图片可完全用户自定义。",
          topImg: require("@/assets/product/beautySDK/水印LOGO/水印logo--大图.png"),
          item: [
            {
              itemTitle: "高自定义性",
              itemText:
                "可自定义水印图片、位置、水印个数，用户任何时候可在屏幕任何位置添加任何图片作为水印，可以是公司logo，可以是品牌logo，甚至可以循环添加多张水印以达到防用功能。拓幻SDK提供超高的性能，毫秒级别水印添加速度。",
              itemImg: require("@/assets/product/beautySDK/水印LOGO/水印logo--高自定义.png"),
            },
            {
              itemTitle: "支持实时和图片渲染",
              itemText:
                "直播平台可根据自己业务需求，在拓幻SDK提供的平台上直播期间添加logo水印，也可在录播中添加logo水印。特效相机应用可根据需求，对录制视频或者拍照以及用户相册进行水印添加。",
              itemImg: require("@/assets/product/beautySDK/水印LOGO/水印logo--实时渲染.png"),
            },
          ],
        },
        {
          title: "精致面具",
          topText:
            "【TiFancy美颜SDK】基于自研的人脸识别和人脸跟踪技术，对106个人脸关键点实时追踪，可以生成与人脸表情高度融合的面具。",
          topImg: require("@/assets/product/beautySDK/精致面具/精致面具--大图.png"),
          item: [
            {
              itemTitle: "多种款式",
              itemText:
                "拓幻资源库拥有多套面具可选，可存放云端也可存放本地，充分满足客户不同需求。",
              itemImg: require("@/assets/product/beautySDK/精致面具/精致面具--多种款式.png"),
            },
            {
              itemTitle: "个性定制",
              itemText:
                "客户可根据自己的业务需求，定制适合自己用户的产品。我们热情、专业的设计师会和您沟通并为您提供一站式定制设计服务。",
              itemImg: require("@/assets/product/beautySDK/精致面具/精致面具--个性定制.png"),
            },
          ],
        },
        {
          title: "哈哈镜",
          topText:
            "【TiFancy美颜SDK】哈哈镜特效为用户提供实时哈哈镜功能，直播平台、特效相机、短视频平台可在视频录制或直播功能中嵌入该功能，以增加恶搞乐趣。多种哈哈镜扭曲效果，配合恶搞贴纸秒变如花妆、大嘴巴、或者冰块方脸，多种玩法配合，刷爆朋友圈。",
          topImg: require("@/assets/product/beautySDK/哈哈镜/哈哈镜--大图.png"),
          item: [
            {
              itemTitle: '"哈哈镜+"玩法',
              itemText:
                "有趣的哈哈镜效果配合贴纸功能，将哈哈镜特效不再停留在变形阶段，拓幻SDK提供丰富的组合功能，经验丰富的产品设计，精心打造趣味十足的哈哈镜+效果。拓幻SDK提供超高的性能，整体时长超过10秒的大型场景特效，空间占用率小于1M，加载时间毫秒级。",
              itemImg: require("@/assets/product/beautySDK/哈哈镜/哈哈镜--哈哈镜+玩法gif.gif"),
            },
            {
              itemTitle: "支持定制",
              itemText:
                "用户可根据自己业务需求，在拓幻SDK提供的平台上定制开发各类哈哈镜效果，让平台用户发挥想象力，配合自定义贴纸，玩出最符合自己平台需求的花样。我们专业的产品团队将为您提供一站式定制设计服务。",
              itemImg: require("@/assets/product/beautySDK/哈哈镜/哈哈镜--支持定制.png"),
            },
          ],
        },
        // 单独页面
        {
          title: "一键美颜",
        },
        {
          title: "脸型精修",
          topText:
            "【TiFancy美颜SDK】基于图像识别和人脸追踪算法，整合多种网红风格一步到位实现脸型精修功能，让每个拥有独特脸型的客户得到更舒心的使用体验。",
          topImg: require("@/assets/product/beautySDK/脸型精修/脸型精修--大图gif.gif"),
          item: [
            {
              itemTitle: "多种款式",
              itemText:
                "为不同脸型的客户精心研制了不同的脸型模版，让每一位客户都可以根据自身脸部特点得到最优的美型效果。",
              itemImg: require("@/assets/product/beautySDK/脸型精修/脸型精修--多种款式gif.gif"),
            },
            {
              itemTitle: "自定义调节",
              itemText:
                "脸型精修助力用户更加快速的美颜美型，还可以根据不同用户特点随意调节模版，达到让每个用户满意的效果。",
              itemImg: require("@/assets/product/beautySDK/脸型精修/脸型精修--自定义调节.png"),
            },
          ],
        },
        {
          title: "绿幕抠图",
          topText:
            "【TiFancy美颜SDK】基于图像处理技术，实现了移动端的虚拟场景模式，给到用户惊艳无比的视觉效果，让用户体验到虚拟与现实之间带来的真实互动感，带来极佳的感官体验。",
          topImg: require("@/assets/product/beautySDK/绿幕抠图/绿幕抠图--大图.png"),
          item: [
            {
              itemTitle: "场景替换",
              itemText:
                "拥有多种高大上的绿幕特效场景，瞬间替换，实现沉浸式裸眼3D效果，现实与虚拟共存，让用户视觉拥有更多可能性。",
              itemImg: require("@/assets/product/beautySDK/绿幕抠图/绿幕抠图--场景切换.png"),
            },
            {
              itemTitle: "支持定制",
              itemText:
                "用户可根据自己业务需求，发挥想象力，定制专属于自己的绿幕特效场景，玩出最符合自己平台需求的花样。我们专业的产品团队将为您提供一站式定制设计服务，打造电影般的未来场景。",
              itemImg: require("@/assets/product/beautySDK/绿幕抠图/绿幕抠图--支持定制.png"),
            },
          ],
        },
        {
          title: "长腿瘦身",
          topText:
            "【TiFancy美颜SDK】通过AI技术对用户全身进行精细调整，瘦身、长腿功能一应俱全，打造完美身材。",
          topImg: require("@/assets/product/beautySDK/长腿瘦身/长腿瘦身--大图gif.gif"),
          item: [
            {
              itemTitle: "精准美体",
              itemText:
                "基于人体检测和关键点定位技术，快速定位并输出高精度人体关键点，即便是在各种复杂场景下也能准确定位人体数据，对人体数据进行调整，达到美体效果。",
              itemImg: require("@/assets/product/beautySDK/长腿瘦身/长腿瘦身--精准美体.png"),
            },
            {
              itemTitle: "自定义调整",
              itemText:
                "用户可根据自身需求，对人物身体进行自定义精细化调整，到达美体最优效果。",
              itemImg: require("@/assets/product/beautySDK/长腿瘦身/长腿瘦身--自定义调节.png"),
            },
          ],
        },
        // 单独页面
        {
          title: "手势识别",
        },
        {
          title: "发色变换",
          topText:
            "【TiFancy美颜SDK】基于发色变换技术，可以将头发染成任意颜色，让您一键实时切换发色。",
          topImg: require("@/assets/product/beautySDK/发色变换/发色变换--大图gif.gif"),
          item: [
            {
              itemTitle: "精确识别",
              itemText:
                "基于自研的发色变换技术，可以精确识别用户头发数据，一键切换发色，真实还原质地。",
              itemImg: require("@/assets/product/beautySDK/发色变换/发色变换--精确识别.png"),
            },
            {
              itemTitle: "自定义色值",
              itemText:
                "用户可根据自身需求，自定义头发色值，获得更良好的使用体验。",
              itemImg: require("@/assets/product/beautySDK/发色变换/发色变换----自定义色值.png"),
            },
          ],
        },
        {
          title: "动态礼物",
          topText:
            "【TiFancy美颜SDK】3D送礼物特效为直播平台提供3D送礼物功能，直播平台可在打赏主播等功能中嵌入该功能。动态3D全屏效果，酷炫的3D模型动画加上粒子特效，将送礼物功能不再停留在2D的游艇和火箭，彰显打赏金主尊享身份。",
          topImg: require("@/assets/product/beautySDK/动态礼物/动态礼物--大图gif.gif"),
          item: [
            {
              itemTitle: "3D效果",
              itemText:
                "酷炫的3D效果配合粒子特效系统，将礼物特效不再停留在2D和单调之中，拓幻SDK提供丰富的礼物场景特效，经验丰富的特效师和模型师，精心打造创意十足的礼物效果。拓幻SDK提供超高的性能，整体时长超过10秒的大型场景特效，空间占用率小于1M，加载时间毫秒级。",
              itemImg: require("@/assets/product/beautySDK/动态礼物/动态礼物--3d效果gif.gif"),
            },
            {
              itemTitle: "支持定制",
              itemText:
                "直播平台可根据自己业务需求，在拓幻SDK提供的平台上自行制作全屏送礼物效果，让平台自由发挥想象力，送出最符合自己平台需求的礼物。支持定制，我们专业的视频处理团队将为您提供一站式定制设计服务。",
              itemImg: require("@/assets/product/beautySDK/动态礼物/动态礼物--支持定制gif.gif"),
            },
          ],
        },
        {
          title: "人像抠图",
          topText:
            "【TiFancy美颜SDK】基于自研的AI人像背景分割技术，自动准确的识别出图像中的人体轮廓，实时将人像与背景分割开来，并替换合适背景。广泛应用于人像抠图美化、照片背景更换等互动娱乐场景。",
          topImg: require("@/assets/product/beautySDK/人像抠图/人像抠图--大图gif.gif"),
          item: [
            {
              itemTitle: "场景替换",
              itemText:
                "基于人脸识别和人像分割技术，可精确定位人脸106个关键点，自动精准的识别出图像中的人体轮廓，并替换拓幻资源库中精致多变的背景，给用户极致的感官体验。",
              itemImg: require("@/assets/product/beautySDK/人像抠图/人像抠图--场景切换.png"),
            },
            {
              itemTitle: "支持定制",
              itemText:
                "用户可根据自己业务需求，自由发挥想象力，只要客户能想到的场景，虚拟人像抠图技术都能为你实现，我们专业的产品团队将为您提供一站式定制设计服务。",
              itemImg: require("@/assets/product/beautySDK/人像抠图/人像抠图--支持定制gif.gif"),
            },
          ],
        },
      ],
      selectId: 0, //导航选中
      //右侧显示数据
      showItem: null,
    };
  },
  created() {
    this.toShow();
  },
  watch: {
    $route() {
      this.toShow();
    },
  },
  methods: {
    // 接收跳转显示
    toShow() {
      let index = this.$route.query.id;
      let item = this.leftList[index];
      this.show(item, index);
    },
    //点击显示
    show(item, index) {
      this.selectId = index;
      this.showItem = item;
      /* bus.$emit('getIndex',index) */
    },
  },
};
</script>
<style lang="scss" scoped>
$width:79.1%;
//中间内容部分
.conter {
  width: $width;
  margin: auto;

  //列表部分
  .nav_content {
    display: flex;
    .left {
      margin-right: 254px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
      li {
        height: 36px;
        line-height: 36px;
        cursor: pointer;
      }
      li:hover {
        color: #4c8cf5;
      }
      .select_li {
        width: 125px;
        text-align: center;
        font-size: 16px;
        color: #4c8cf5;
        background: url("~@/assets/product/icon_product_sel@2x.png")
          no-repeat 0 center;
        background-size: 11px 17px;
        vertical-align: middle;
      }
    }
    .right {
      width: 69.2%;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      .right_top {
        h3 {
          height: 36px;
          line-height: 36px;
          font-size: 16px;
          font-weight: 500;
          color: #222222;
        }
        p {
          line-height: 24px;
          margin: 20px 0;
        }
        .item_img {
          width: 100%;
          overflow: hidden;
          img {
            width: 100%;
            transition: all 1s;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
      }
      .right_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 60px 0;
        .item_img {
          width: 50.6%;
          overflow: hidden;
          img {
            width: 100%;
            transition: all 1s;
          }
          img:hover {
            transform: scale(1.2);
          }
        }

        .item_text {
          width: 43.1%;
          h3 {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
          }
          p {
            line-height: 24px;
            margin: 18px 0;
          }
        }
      }
    }
  }
}
</style>