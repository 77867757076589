<template>
  <!-- 右侧内容 -->
  <div class="right">
    <div class="right_top">
      <h3>互动贴纸</h3>
      <p>
        【TiFancy美颜SDK】基于自研的人脸识别和人脸跟踪技术，对106个人脸关键点实时追踪，在动态贴纸的基础上，进行丰富有趣的人机互动，根据不同的表情，触发各类有趣的特效，大大提高了平台的趣味性。
      </p>
      <div class="top_img">
        <img :src="topIndex == 0 ? topDef : topSel" />
        <span
          class="top_span"
          @mouseover="topIndex = 1"
          @mouseleave="topIndex = 0"
          >挑眉</span
        >
      </div>
    </div>
    <div>
      <div class="right_item">
        <div class="item_img">
          <img :src="eye == 0 ? eyeDef : eyeSel" />
          <span class="item_span" @mouseover="eye = 1" @mouseleave="eye = 0"
            >眨眼</span
          >
        </div>
        <div class="item_text">
          <h3>表情触发</h3>
          <p>
            基于拓幻科技自研的表情识别技术，可有效识别人脸多种表情，并根据不同表情触发各类有趣的特效贴纸。
          </p>
        </div>
      </div>
      <div class="right_item">
        <div class="item_text">
          <h3>多种款式</h3>
          <p>
            拓幻贴纸库拥有多套互动贴纸可选择使用，可存放云端也可存放本地。张嘴、眨眼、挑眉等多种互动样式可选，解锁更多互动玩法。
          </p>
        </div>
        <div class="item_img">
          <img :src="mouth == 0 ? mouthDef : mouthSel" />
          <span class="item_span" @mouseover="mouth = 1" @mouseleave="mouth = 0"
            >张嘴</span
          >
        </div>
      </div>
      <div class="right_item">
        <div class="last_img">
          <img
            src="@/assets/product/beautySDK/互动贴纸/互动贴纸--个性定制.png"
            alt=""
          />
        </div>
        <div class="item_text">
          <h3>个性定制</h3>
          <p>
            个性定制贴纸，按照自己的审美或需求，定制适合自己用户的产品。我们为您提供生成贴纸配置文件工具以及贴纸制作样例，可自行个性化配置设计，也可将设计要求提给我们，我们热情，专业的设计师会和您沟通并为您提供一站式定制设计服务。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      topDef: require("@/assets/product/beautySDK/互动贴纸/互动贴纸--大图--挑眉gif.gif"),
      topSel: require("@/assets/product/beautySDK/互动贴纸/互动贴纸--大图--挑眉后gif.gif"),
      eyeDef: require("@/assets/product/beautySDK/互动贴纸/互动贴纸--表情触发--眨眼gif.gif"),
      eyeSel: require("@/assets/product/beautySDK/互动贴纸/互动贴纸--表情触发--眨眼后gif.gif"),
      mouthDef: require("@/assets/product/beautySDK/互动贴纸/互动贴纸--多种款式--张嘴gif.gif"),
      mouthSel: require("@/assets/product/beautySDK/互动贴纸/互动贴纸--多种款式--张嘴后gif.gif"),
      //悬停数据
      topIndex: 0,
      eye: 0,
      mouth: 0,
    };
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 69.2%;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  // 相同样式
  span {
    display: block;
    width: 100px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
  }
  span:hover {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #ffffff;
  }
  .right_top {
    h3 {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
    p {
      line-height: 24px;
      margin: 20px 0;
    }
    .top_img {
      width: 100%;
      position: relative;
      img {
        width: 100%;
      }
      .top_span {
        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 10;
      }
    }
  }
  .right_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    .item_img:nth-child(1),
    .item_img:nth-child(2) {
      width: 50.6%;
      position: relative;
      img {
        width: 100%;
      }
      .item_span {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 10;
      }
    }
    .last_img {
      width: 50.6%;
      overflow: hidden;
      img {
        width: 100%;
        transition: all 1s;
      }
      img:last-child:hover {
        transform: scale(1.2);
      }
    }

    .item_text {
      width: 43.1%;
      h3 {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
      p {
        line-height: 24px;
        margin: 20px 0;
      }
    }
  }
}
</style>