<template>
  <!-- 右侧内容 -->
  <div class="right">
    <div class="right_top">
      <h3>滤镜特效</h3>
      <p>
        【TiFancy美颜SDK】特效滤镜功能为用户提供多种趣味滤镜和调色滤镜，为用户增加更多玩法，提升镜头格调。滤镜样式可根据用户需求，进行自定义设计。支持实时预览和后期处理两种模式。
      </p>
      <img class="bigImg" :src="bigImg" />
      <div class="flex">
        <div
          class="icon_box"
          v-for="(item, i) in typeList"
          :key="i"
          @mousemove="hoverImg(item, i)"
        >
          <img
            :class="i == iconIndex ? 'selImg' : ''"
            :src="i == iconIndex ? item.selImg : item.defImg"
            alt=""
          />
          <span :class="i == iconIndex ? 'span' : ''">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="right_item">
        <div class="item_img">
          <img src="@/assets/product/beautySDK/滤镜特效/趣味滤镜.png" alt="" />
        </div>
        <div class="item_text">
          <h3>趣味滤镜</h3>
          <p>
            拓幻滤镜库拥有多套趣味滤镜可供选择，实现素描，卡通，胶片，马赛克，油画，蜡笔，水晶球，水墨画等效果，使短视频，特效相机等应用更具趣味性。渲染效率高，速率快，可实时预览，也可后期处理。
          </p>
        </div>
      </div>
      <div class="right_item">
        <div class="item_text">
          <h3>调色滤镜</h3>
          <p>
            调色滤镜包含咖啡，可可，森女系，慕斯以及北欧风等多款不同调色滤镜。增加拍照，短视频，直播色调风格的提升，将镜头里的一切变得更有格调。调色滤镜我们也提供定制化服务，可自定义调色设计，也可像我们提出需求，我们进行设计调色。
          </p>
        </div>
        <div class="item_img">
          <img src="@/assets/product/beautySDK/滤镜特效/调色滤镜.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 美型点击数据
      typeList: [
        {
          name: "原图",
          defImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_yuantu_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_yuantu_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/滤镜特效/大图--原图.png"),
        },
        {
          name: "水光",
          defImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_shuiguang_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_shuiguang_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/滤镜特效/大图--水光.png"),
        },
        {
          name: "日系",
          defImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_rixi_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_rixi_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/滤镜特效/大图--日系.png"),
        },
        {
          name: "少女",
          defImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_shaonv_normal@2x.png"),
          selImg: require("@//assets/product/beautySDK/滤镜特效/icon_lvjing_shaonv_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/滤镜特效/大图--少女.png"),
        },
        {
          name: "复古",
          defImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_fugu_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_fugu_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/滤镜特效/大图--复古.png"),
        },
        {
          name: "灰调",
          defImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_huidiao_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/滤镜特效/icon_lvjing_huidiao_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/滤镜特效/大图--灰调.png"),
        },
      ],
      bigImg: require("@/assets/product/beautySDK/滤镜特效/大图--原图.png"),
      iconIndex: 0,
    };
  },
  methods: {
    // 鼠标悬停
    hoverImg(item, i) {
      this.iconIndex = i;
      this.bigImg = item.typyImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 69.2%;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  .right_top {
    h3 {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
    p {
      line-height: 24px;
      margin: 20px 0;
    }
    .bigImg {
      width: 100%;
    }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon_box {
        width: 11.5%;
        margin-top: 38px;
        text-align: center;
        img {
          width: 100%;
          cursor: pointer;
          transition: all 1s;
        }
        span {
          height: 30px;
          display: block;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 40px;
        }
        .selImg {
          transform: scale(1.1);
        }
        .span {
          color: #4c8cf5;
        }
      }
    }
  }
  .right_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    .item_img {
      width: 50.6%;
      overflow: hidden;
      img {
        width: 100%;
        transition: all 1s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }

    .item_text {
      width: 43.1%;
      h3 {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
      p {
        line-height: 24px;
        margin: 20px 0;
      }
    }
  }
}
</style>