<template>
  <!-- 右侧内容 -->
  <div class="right">
    <div class="right_top">
      <h3>精致美型</h3>
      <p>
        【TiFancy美颜SDK】实时美型可以自由放大眼睛，收缩脸颊，微调整额头、下巴、嘴型，瘦鼻等，不失真，又能够完美调整脸型，搞定各个场合的自拍和Vlog。
      </p>
      <img class="bigImg" :src="bigImg" />
      <div class="flex">
        <div
          class="icon_box"
          v-for="(item, i) in typeList"
          :key="i"
          @mousemove="hoverImg(item, i)"
        >
          <img
            :class="i == iconIndex ? 'selImg' : ''"
            :src="i == iconIndex ? item.selImg : item.defImg"
            alt=""
          />
          <span :class="i == iconIndex ? 'span' : ''">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="right_item">
        <div class="item_img">
          <img
            src="@/assets/product/beautySDK/精致美型/精致美型--精准微整形.png"
            alt=""
          />
        </div>
        <div class="item_text">
          <h3>精准微整型</h3>
          <p>
            基于人脸识别和人脸跟踪技术，精准定位106个人脸关键点，精确捕捉面部五官，提供可自由调节的大眼、瘦脸外，更有针对五官细节，如额头、下巴、嘴巴、鼻子等微整形功能，充分满足不同客户需求，实现实时美型微整形。
          </p>
        </div>
      </div>
      <div class="right_item">
        <div class="item_text">
          <h3>多场景使用</h3>
          <p>
            支持实时渲染，完美适应逆光、暗角等环境，让您轻松打造精致五官，应对不同场景需求。目前已提供针对人脸的20多项面部微整形。
          </p>
        </div>
        <div class="item_img">
          <img
            src="@/assets/product/beautySDK/精致美型/精致美型--多场景使用.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 美型点击数据
      typeList: [
        {
          name: "大眼",
          defImg: require("@/assets/product/beautySDK/精致美型/icon_dayan_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/精致美型/icon_dayan_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/精致美型/精致美型--大眼gif.gif"),
        },
        {
          name: "瘦脸",
          defImg: require("@/assets/product/beautySDK/精致美型/icon_shoulian_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/精致美型/icon_shoulian_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/精致美型/精致美型--瘦脸gif.gif"),
        },
        {
          name: "瘦鼻",
          defImg: require("@/assets/product/beautySDK/精致美型/icon_shoubi_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/精致美型/icon_shoubi_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/精致美型/精致美型--瘦鼻gif.gif"),
        },
        {
          name: "额头",
          defImg: require("@/assets/product/beautySDK/精致美型/icon_etou_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/精致美型/icon_etou_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/精致美型/精致美型--额头gif.gif"),
        },
        {
          name: "嘴型",
          defImg: require("@/assets/product/beautySDK/精致美型/icon_zuixing_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/精致美型/icon_zuixing_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/精致美型/精致美型--嘴型gif.gif"),
        },
        {
          name: "下巴",
          defImg: require("@/assets/product/beautySDK/精致美型/icon_xiaba_normal@2x.png"),
          selImg: require("@/assets/product/beautySDK/精致美型/icon_xiaba_hover@2x.png"),
          typyImg: require("@/assets/product/beautySDK/精致美型/精致美型--下巴gif.gif"),
        },
      ],
      bigImg: require("@/assets/product/beautySDK/精致美型/精致美型--大眼gif.gif"),
      iconIndex: 0,
    };
  },
  methods: {
    // 鼠标悬停
    hoverImg(item, i) {
      this.iconIndex = i;
      this.bigImg = item.typyImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 69.2%;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  .right_top {
    h3 {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
    p {
      line-height: 24px;
      margin: 20px 0;
    }
    .bigImg {
      width: 100%;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon_box {
        width: 11.5%;
        margin-top: 38px;
        text-align: center;
        img {
          width: 100%;
          cursor: pointer;
          transition: all 1s;
          border-radius: 50%;
        }
        span {
          height: 30px;
          display: block;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 40px;
        }
        .selImg {
          transform: scale(1.1);
          box-shadow: 0px 8px 8px 0px rgba(112, 168, 255, 0.4);
        }
        .span {
          color: #4c8cf5;
        }
      }
    }
  }
  .right_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    .item_img {
      width: 50.6%;
      overflow: hidden;
      img {
        width: 100%;
        transition: all 1s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }

    .item_text {
      width: 43.1%;
      h3 {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
      p {
        line-height: 24px;
        margin: 20px 0;
      }
    }
  }
}
</style>