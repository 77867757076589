<template>
  <div>
    <div v-if="show" class="bread_nav">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">
          <span>首页</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item
          v-for="(item, index) in list"
          :key="item.path"
          :to="{ path: item.path }"
        >
          <span :class="index == list.length - 1 ? 'select' : ''">{{
            item.meta.title
          }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-else class="nav">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(item, index) in list"
          :key="item.path"
          :to="{ path: item.path }"
        >
          <span :class="index == list.length - 1 ? 'select' : ''">{{
            item.meta.title
          }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: null,
      show: true,
    };
  },
  created() {
    this.getName();
  },
  watch: {
    $route() {
      this.getName();
    },
  },
  methods: {
    getName() {
      let matched = this.$route.matched.filter((item) => item.meta.name);
      // 判断是否首页做前缀
      let name = matched[0].meta.name;
      if (name == "Developer") {
        this.show = false;
      }
      this.list = matched;
    },
  },
};
</script>
<style lang="scss" scoped>
//面包屑导航
.bread_nav {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #eee;
  margin-bottom: 51px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;

  .breadcrumb {
    line-height: 80px;
  }
  .select {
    color: #222;
  }
}
.el-breadcrumb__item span {
  color: #999;
}
.nav {
  width: 100%;
  height: 80px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;

  .breadcrumb {
    line-height: 80px;
  }

  .select {
    color: #222;
  }
}
</style>