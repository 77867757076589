<template>
  <div class="top" :style="{ backgroundImage: 'url(' + bigData.topBg + ')' }">
    <div
      class="top_text"
      :style="{ backgroundImage: 'url(' + bigData.topImg + ')' }"
    >
      <h1 class="top_h">{{ bigData.topTitle }}</h1>
      <p class="top_p">
        {{ bigData.topText }}
      </p>
      <p class="top_p">
        {{ bigData.topText2 }}
      </p>

      <span @click="toDemo" class="select_a">Demo预览</span>
      <span @click="toCall">{{ bigData.topBtn }}</span>
       <span @click="toDoc">更新日志</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["list"],
  data() {
    return {
      bigData: this.list,
    };
  },
  watch: {
    list(newVal) {
      this.bigData = newVal;
    },
  },
  methods: {
    toCall() {
      this.$router.push("/call");
    },
    toDemo() {
      this.$router.push("/demo");
    },
    toDoc() {
      this.$router.push("/developer/platform");
    },
  },
};
</script>
<style lang="scss" scoped>
//背景大图部分
.top {
  width: 100%;
  height: 500px;
  line-height: 425px;
  text-align: left;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 500px;
  .top_text {
    width: 1440px;
    margin: auto;
    height: 100%;
    .top_h {
      margin-left: 60px;

      height: 66px;
      font-size: 50px;
      font-weight: 500;
    }
    .top_p {
      // margin-left: 20px;
      // height: 63px;
      // font-size: 16px;
      // font-weight: 400;

      margin-left: 60px;
      height: 16px;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 25px;
      margin-top: 10px;
    }
    span {
      margin-left: 60px;
      margin-top: 220px;
      text-align: center;
      margin-right: 8px;

      display: inline-block;
      width: 160px;
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #ffffff;
      border-radius: 4px;
      line-height: 40px;
      cursor: pointer;
    }

    // span:hover {
    //   background: rgba(255, 255, 255, 0.2);
    //   border: 1px solid #ffffff;
    // }
    span:hover {
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid #ffffff;
      border-radius: 4px;
    }
    .select_a {
      background: #4C8CF5;
      border: 0;
    }
    .select_a:hover {
      background: #2673f1;
      border: 0;
    }

  }
}
</style>