<template>
  <!-- 右侧内容 -->
  <div class="right">
    <div class="right_top">
      <h3>全局美颜</h3>
      <p>
        【TiFancy美颜SDK】集成精致的磨皮、美白、粉嫩，鲜明等美颜功能，可以自由调整磨皮、美白、粉嫩、鲜明程度。根据用户需求的不同，自由调配人像美化效果精准控制美颜特效和全局影像美化。
      </p>
      <div class="bea_img" @mousedown="show = false" @mouseup="show = true">
        <span class="text" v-show="show">左右拉动轴线</span>
        <img-comparison-slider class="slider-with-custom-handle">
          <img
            slot="after"
            width="100%"
            src="@/assets/product/beautySDK/全局美颜/全局美颜--大图--美颜前.png"
          />
          <img
            slot="before"
            width="100%"
            src="@/assets/product/beautySDK/全局美颜/全局美颜--大图--美颜后.png"
          />
        </img-comparison-slider>
      </div>
    </div>
    <div>
      <div class="right_item">
        <div class="item_img">
          <img
            src="@/assets/product/beautySDK/全局美颜/全局美颜--自由调节.png"
            alt=""
          />
        </div>
        <div class="item_text">
          <h3>自由调节</h3>
          <p>
            提供可自由调节的磨皮、美白、粉嫩、鲜明、亮度功能，全局美化，充分满足不同客户需求。
          </p>
        </div>
      </div>
      <div class="right_item">
        <div class="item_text">
          <h3>可靠性</h3>
          <p>
            美颜效果不受环境影响，完美适应逆光、暗角等环境，支持单人及多人模式下自拍、直播和视频。
          </p>
        </div>
        <div class="item_img">
          <img
            src="@/assets/product/beautySDK/全局美颜/全局美颜--可靠性.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 69.2%;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  .right_top {
    h3 {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
    p {
      line-height: 24px;
      margin: 20px 0;
    }
    .slider-with-custom-handle {
      width: 100%;
      --handle-size: 30px;
      --handle-opacity: 0.5;
      --handle-opacity-active: 0.5;
    }
    .custom-handle {
      width: var(--handle-size);
      height: var(--handle-size);
    }

    .bea_img {
      position: relative;
    }
    .text {
      z-index: 9;
      position: absolute;
      right: 2%;
      bottom: 2%;
      color: #ffffff;
    }
  }
  .right_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    .item_img {
      width: 50.6%;
      overflow: hidden;
      img {
        width: 100%;
        transition: all 1s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }

    .item_text {
      width: 43.1%;
      h3 {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
      p {
        line-height: 24px;
        margin: 18px 0;
      }
    }
  }
}
</style>