<template>
  <!-- 右侧内容 -->
  <div class="right">
    <div class="right_top">
      <h3>一键美颜</h3>
      <p>
        【TiFancy美颜SDK】基于图像识别和人脸追踪算法，整合多种网红风格一步到位实现一键美颜功能，用户更省心。
      </p>
      <div class="bea_img" @mousedown="show = false" @mouseup="show = true">
        <img-comparison-slider class="slider-with-custom-handle">
          <img
            slot="after"
            width="100%"
            src="@/assets/product/beautySDK/一键美颜/一键美颜--大图--美颜前.png"
          />
          <img
            slot="before"
            width="100%"
            src="@/assets/product/beautySDK/一键美颜/一键美颜--大图--美颜后.png"
          />
        </img-comparison-slider>
        <span class="text" v-show="show">左右拉动轴线</span>
      </div>
    </div>
    <div>
      <div class="right_item">
        <div class="item_img">
          <img
            src="@/assets/product/beautySDK/一键美颜/一键美颜--多种款式.png"
            alt=""
          />
        </div>
        <div class="item_text">
          <h3>多种款式</h3>
          <p>
            拓幻资源库拥有标准、可爱、网红、女神、初恋等十几种美颜模版，给客户提供了更加多样化的美颜选择，用户可随心所欲的选择自己适合的模版。
          </p>
        </div>
      </div>
      <div class="right_item">
        <div class="item_text">
          <h3>自定义调节</h3>
          <p>
            一键美颜助力用户更加快速的美颜美型，还可以根据不同用户特点随意调节模版，达到让每个用户满意的效果。
          </p>
        </div>
        <div class="item_img">
          <img
            src="@/assets/product/beautySDK/一键美颜/一键美颜--自定义调节.png"
            alt=""
          />
        </div>
      </div>
      <div class="right_item">
        <div class="item_img">
          <img
            src="@/assets/product/beautySDK/一键美颜/一键美颜--低端机适配.png"
            alt=""
          />
        </div>
        <div class="item_text">
          <h3>低端机适配</h3>
          <p>
            基于客户手机硬件性能等局限性，拓幻推出低端机适配一键美颜模版，即便是性能再差的手机也能享受一键美颜带来的简单省心，摆脱卡顿，给用户最贴心的使用体验。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      show: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 69.2%;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  .right_top {
    h3 {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
    p {
      line-height: 24px;
      margin: 20px 0;
    }
    .slider-with-custom-handle {
      width: 100%;
      --handle-size: 30px;
      --handle-opacity: 0.5;
      --handle-opacity-active: 0.5;
    }
    .bea_img {
      position: relative;
    }
    .text {
      z-index: 9;
      position: absolute;
      left: 2%;
      bottom: 2%;
      color: #ffffff;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .right_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    .item_img {
      width: 50.6%;
      overflow: hidden;
      img {
        width: 100%;
        transition: all 1s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }

    .item_text {
      width: 43.1%;
      h3 {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
      p {
        line-height: 24px;
        margin: 20px 0;
      }
    }
  }
}
</style>